import '../Canvas.css'
import './Tag.css'
import GLOBAL from '../Global'
import {useState, useEffect} from 'react'
import { useHistory } from "react-router-dom";
import {CgCopy} from 'react-icons/cg'
import {RiLoader3Fill} from 'react-icons/ri'
import {MdNavigateNext, MdNavigateBefore} from 'react-icons/md'


const balanceEndpoint = process.env.REACT_APP_MOCHIMO_API_HTTP_ADDRESS + "/api/v2/bc/balance";
const mempoolEndpoint = process.env.REACT_APP_MOCHIMO_API_HTTP_ADDRESS + "/node/v1/hcblock";
const transactionEndpoint = process.env.REACT_APP_MOCHIMO_API_HTTP_ADDRESS + "/bx/v2/txs";

const transactionTableSize = 25;

function Tag() {

    const [balance, setBalance] = useState(null);
    const [mempool, setMempool] = useState(null);
    const [transactions, setTransactions] = useState({transactions: [], lastPageId: null});
    const [txRange, setTxRange] = useState({start: 0, end:transactionTableSize});

    const tag = window.location.href.substring(window.location.href.lastIndexOf('/') + 1);
    const history = useHistory();
    
    const loadBalance = () => {
        fetch(balanceEndpoint + "/" + tag)
        .then((response) => response.json())
        .then((data) => {
            if(data.error || !data.address || data.balance == 0){
                console.warn(data);
                history.push({
                    pathname: '/error',
                    state: { msg: data.error ? "Invalid tag" : "Tag not found" }
                  })
            }else{
                document.getElementById("tag-balance-store").value = data && data.balance ? data.balance : null;
                setBalance(data)
                document.getElementById('tag-load-icon').style.display = "none";
                var elements = document.getElementsByClassName('tag');
                for(var i = 0;i < elements.length;i++) {
                    var elem = elements[i];
                    elem.style.display = 'inherit'
                }
            }
        }).catch((e) => {console.error(e)});
    }

    const loadMempool = () => {
        fetch(mempoolEndpoint + "?address=" + tag)
        .then((response) => response.json())
        .then((data) => {
            if(data.error)
                console.warn(data);
            else{
                
                let txs = [];
                for(var i = 0;i < data.transactions.length;i++) {
                    let ctx = data.transactions[i];
                    console.log(ctx)
                    let tx = ctx.transaction;
                    tx['receivedUnixTimestamp'] = ctx.receivedUnixTimestamp;
                    txs.push(tx);
                }

                data.transactions = txs;
                setMempool(data);
            }
        }).catch((e) => {console.error(e)});
    }

    const loadTransactions = () => {
        if(transactions.lastPageId !== "end" && transactions.transactions.length < txRange.end) {
            fetch(transactionEndpoint + "?address=" + tag + (transactions.lastPageId ? "&afterPageId=" + transactions.lastPageId : ""))
            .then((response) => response.json())
            .then((data) => {
                if(data.error)
                    console.warn(data);
                else
                    setTransactions({transactions: transactions.transactions.concat(data.transactions), lastPageId: data.pageSize >= data.maxPageSize ? data.pageId : "end"})
            }).catch((e) => {console.error(e)});
        }
    }

    const nextTxRange = () => {
        if(txRange.end >= transactions.transactions.length && transactions.lastPageId === "end")
            return;
        let newTxRange = {start: txRange.end, end: txRange.end + transactionTableSize};
        setTxRange(newTxRange)
        txRange.start = newTxRange.start;
        txRange.end = newTxRange.end;
        loadTransactions();
    }
    
    const previousTxRange = () => {
        if(txRange.start <= 0)
            return;
        setTxRange({start: txRange.start - transactionTableSize, end: txRange.start})
    }

    const computeValue = () => {
        // this is to load the value dollar of the account without waiting to request the tag balance
        let balanceValue = document.getElementById('tag-balance-store') ? document.getElementById('tag-balance-store').value : null;
        let mcmQuote = document.getElementById('mcm-usd-quote') ? document.getElementById('mcm-usd-quote').innerHTML : null;

        if(balanceValue && mcmQuote)
            document.getElementById("tag-value").innerHTML = "$ " + ( balanceValue * mcmQuote / 1000000000).toFixed(2) + " @ " + mcmQuote + "/MCM";
        else
            document.getElementById("tag-value").innerHTML = " -- @ --";
    }

    const parseAge = (tx) => {
        if(!balance)
            return null;
        const elapsed = balance.block.height - tx.height;
        if(elapsed == 0)
            return "Just now";
        if(elapsed == 1)
            return elapsed + " block ago";
        return elapsed + " blocks ago";
    }

    const parseDirection = (tx) => {

        let direction = null;
        if(tx.source.tag && tx.source.tag === tag)
        {
            direction = 'OUT'
            for(let i = 0;i<tx.destinations.length;i++)
                if(tx.destinations[i].tag == tag)
                {
                    direction = 'SELF'
                    break;
                }
        }else if ((tx.change.tag && tx.change.tag == tag) && (tx.destinations && tx.destinations.length == 1 && !tx.destinations[0].tag))
            direction = "INIT"
        else
            direction = 'IN'
        
        return direction;
    }

    const getDirectionComponent= (tx) => {
        const direction = parseDirection(tx);
        let backgroundColor;
        let color;
        switch (direction) {
            case "IN":
                //backgroundColor = "#CCF4ED";
                //color = "#02977E";
                backgroundColor = "transparent";
                color = "lightgreen";
            break;
            case "OUT":
                //backgroundColor = "#F8EBCD";
                //color = "#B47D00";
                backgroundColor = "transparent";
                color = "lightsalmon";
            break;
            default:
                backgroundColor = "transparent";
                color = "#626C76";
            break;
        }
        return <div className="tag-transaction-table-direction" style={{backgroundColor: backgroundColor, color: color, opacity: '0.85'}}>{direction}</div>;
    }

    const parseConterpart = (tx) => {
        let counterpart = null;
        if(tx.source.tag && tx.source.tag === tag)
            counterpart = tx.destinations.length > 1 ? "MTX (" + tx.destinations.length + ")" : (tx.destinations[0].address.tag ? tx.destinations[0].address.tag : (GLOBAL.PUBLIC_KEY_ID_HEADER + tx.destinations[0].address.publicKeyId.substring(0, 24) + "..."));
        else
            counterpart = tx.source.tag ? tx.source.tag : GLOBAL.PUBLIC_KEY_ID_HEADER + tx.source.publicKeyId.substring(0, 24 - GLOBAL.PUBLIC_KEY_ID_HEADER.length) 
        
        return counterpart;
    }
    
    const copyToClipboard = (e) =>{
        const copyText = document.getElementById("tag-address")
        copyText.style.display = 'initial';
        copyText.select();
        copyText.setSelectionRange(0, 99999);
        document.execCommand("copy");

        copyText.style.display = 'none';

        var tooltip = document.getElementById("ctc");
        tooltip.innerHTML = "Copied !";

        e = e || window.event;
        if (typeof e.stopPropagation != "undefined") {
            e.stopPropagation();
        } else {
            e.cancelBubble = true;
        }
    }

    const outCopyToClipboard  = () => {
        var tooltip = document.getElementById("ctc");
        tooltip.innerHTML = "Copy tag";
    }

    const humanDuration = (seconds) => {
        let min = Math.floor(seconds / 60);
        let sec = Math.floor(seconds - min * 60);
        return min + "m " + sec + "s"
    }

    const isTxMined = (tx) => {
        if(transactions && transactions.transactions) {
            for(var i = 0;i < Math.min(transactionTableSize * 4, transactions.transactions.length);i++) {
                if(transactions.transactions[i].txid == tx.txid)
                    return true;
            }
        }
        
        return false;
    }

    useEffect(() => {
        setTimeout(loadBalance, GLOBAL.LOADER_DELAY);
        loadTransactions();
        loadMempool();
        computeValue();

        //const delay = 60000;
        //const updateTagBalance = setInterval(loadBalance, delay);
        const updateTagValue = setInterval(computeValue, 1000);
    
        return () => {
                //clearInterval(updateTagBalance);
                //clearInterval(updateTagTransactions);
                clearInterval(updateTagValue);
            }
    }, []);

    return (
        <>
        <div id='tag-load-icon' className="loader-container">
            <RiLoader3Fill />  
        </div>
        <div className="canvas tag" style={{display: 'none'}}>
            <div style={{width: '100%', display: 'flex'}}>
                <div className="tag-header">
                    <input className='tag-input-clipboard' id="tag-address" type="text" defaultValue={tag} readOnly/>
                    <label className="tag-font-family" style={{fontWeight: 'bold', fontSize: '20px', marginRight: '5px'}}>Tag  </label>
                    <label className="tag-font-family" style={{fontWeight: 'lighter', fontSize: '19px', marginRight: '5px'}}>{tag}</label>
                    <button className="tag-btn tooltip" onClick={(e) => copyToClipboard(e)} onMouseOut={() => outCopyToClipboard()}>
                        <CgCopy className="tag-icon" />                        
                        <span className="tooltiptext" id="ctc">Copy tag</span>
                    </button>
                </div>
            </div>
            <div className="card-pair">
                <div className="card">
                    <div className="card-title">
                        <label>Balance</label>
                    </div>
                    <hr className="card-spliter" />
                    <input className='tag-input-clipboard' id="tag-balance-store" type="text" readOnly/>
                    <div className="card-row">
                        <label className="card-row-key">Balance:</label>
                        <label className="card-row-value">
                            {balance && 
                                (balance.balance/1000000000).toFixed(9) + " MCM"
                            }
                        </label>
                    </div>
                    <div className="card-row">
                        <label className="card-row-key">Value:</label>
                        <label className="card-row-value" id="tag-value" style={{marginRight: '5px'}}>
                            {/* Value set by computeValue */}
                        </label>
                    </div>
                </div>
                <div className="card">
                    <div className="card-title">
                        <label>Info</label>
                    </div>
                    <hr className="card-spliter" />
                    <div className="card-row">
                        <label className="card-row-key">Public key:</label>
                        <label className="card-row-value">
                            {balance && balance.address && balance.address.publicKeyId &&
                                balance.address.publicKeyId.substring(0, 64)
                            }
                        </label>
                    </div>
                    <div className="card-row">
                        <label className="card-row-key"></label>
                        <label className="card-row-value">
                        </label>
                    </div>
                </div>
            </div>
            <div style={{flex: '1'}}>
                <div className="card" style={{width: 'auto', visibility:
                    (transactions && transactions.transactions && transactions.transactions.length > 0) || (mempool && mempool.transactions && mempool.transactions.length > 0) 
                    ? 'initial' : 'hidden'}}>
                    <table className="card-table">
                        <thead>
                            <tr>
                                <th>Txid</th>
                                <th>Block</th>
                                <th>Age</th>
                                <th>In/Out</th>
                                <th>Value</th>
                                <th>Counterpart</th>
                            </tr>
                        </thead>
                        <tbody>
                            {txRange.start <= 0 && mempool && mempool.transactions.map((tx) => (
                                   
                                   isTxMined(tx) ? null :

                                    <tr key={tx.txid} className="card-spliter" style={{fontStyle: "italic"}}>
                                        <td>{tx.txid.substring(0, 24)}</td>
                                        <td>-</td>
                                        <td>{humanDuration(new Date().getTime() / 1000 - tx.receivedUnixTimestamp)}</td>
                                        <td>{getDirectionComponent(tx)}</td>
                                        <td style={{textAlign: 'right'}}>{((tx.totalSendAmount + tx.feeAmount)/1000000000).toFixed(9) + " MCM"}</td>
                                        <td>
                                            {(() => {
                                                    const counterpart = parseConterpart(tx);
                                                    if(!counterpart.startsWith(GLOBAL.PUBLIC_KEY_ID_HEADER))
                                                        return <a href={"/tag/" + counterpart} className="navigation-link" style={{fontWeight: 'lighter'}}>{counterpart}</a>;
                                                    else
                                                        return counterpart;
                                                })()
                                            }
                                        </td>
                                    </tr>
                                    ))
                            }
                            {transactions && transactions.transactions && transactions.transactions.slice(txRange.start, txRange.end).map((tx) => (
                                <tr key={tx.height + tx.txid} className="card-spliter">
                                    <td><a href={"/tx/" + tx.txid} className="navigation-link" style={{fontWeight: 'lighter'}}>{tx.txid.substring(0, 24)}</a></td>
                                    <td>{tx.height}</td>
                                    <td>{parseAge(tx)}</td>
                                    <td>{getDirectionComponent(tx)}</td>
                                    <td style={{textAlign: 'right'}}>{((tx.totalSendAmount + tx.feeAmount)/1000000000).toFixed(9) + " MCM"}</td>
                                    <td>
                                        {(() => {
                                                const counterpart = parseConterpart(tx);
                                                if(!counterpart.startsWith(GLOBAL.PUBLIC_KEY_ID_HEADER))
                                                    return <a href={"/tag/" + counterpart} className="navigation-link" style={{fontWeight: 'lighter'}}>{counterpart}</a>;
                                                else
                                                    return counterpart;
                                            })()
                                        }
                                    </td>
                                </tr>
                                ))
                            }
                        </tbody>
                    </table>
                    <div style={{width: '100%', height: '70px', display: 'grid', margin: '30px 0px 0px 0px'}}>
                        <div style={{display: 'flex', flexDirection: 'column'}}>
                            <div style={{display: 'flex', justifyContent: 'center'}} >
                                <div className="tag-icon card-table-navigation-btn" onClick={previousTxRange}>
                                    <MdNavigateBefore size={30} style={{opacity: 1}}/>
                                </div>
                                <div style={{margin: '5px 20px 0px 20px'}}>{txRange.start + (transactions.transactions.length <= 0 ? 0 : 1)} to {Math.min(transactions.transactions.length, txRange.end)}</div>
                                <div className="tag-icon card-table-navigation-btn" onClick={nextTxRange}>
                                    <MdNavigateNext size={30} style={{opacity: 1}}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    );
}

export default Tag;
