import './SearchInput.css'
import {BiSearch} from 'react-icons/bi'

function SearchInput() {

  const search = () => {
    const query = document.getElementById('search-input').value.trim().toLowerCase();
    console.log("Search '" + query + "'")
    var path = null;
    if(query.length == 26 && query.startsWith("0x"))
      path = '/tag/' + query.substring(2);
    else if(query.length == 24)
      path = '/tag/' + query;
    else if(query.length == 66 && query.startsWith("0x"))
      path = '/tx/' + query.substring(2);
    else if(query.length == 64)
      path = '/tx/' + query;
    else
      path = '/block/' + query;

      window.location.href = path;
  }

  const onInputKey = (event) =>{
    if (event.key === 'Enter') {
      event.preventDefault();
      search();     
    }
  }

  return (
    <div className="search-input">
      <div style={{backgroundColor: 'transparent', display: 'flex'}}>
        <input id="search-input" type="text" placeholder="Search by Tag, Txid or Block number" style={{dtop: "0px"}} onKeyPress={onInputKey}/>
        <div className="search-input-search-btn" style={{display: "grid", border: "none", justifyContent: "center", alignItems: "center", verticalAlign: "center", width: '50px'}} onClick={search}>
          <BiSearch style={{ color: 'white', fontSize: '30px', fontWeight: "bold", alignContent: 'center', verticalAlign: 'center', alignSelf: "center"}} />
        </div>
      </div>
    </div>
  );
}

export default SearchInput;