import './Canvas.css'
import Home from './screens/Home'
import Tag from './screens/Tag'
import Transaction from './screens/Transaction'
import Block from './screens/Block'
import Mempool from './screens/Mempool'
import Error from './screens/Error'
import { BrowserRouter, Switch, Route, Link} from "react-router-dom";

function Canvas() {
  return (
    <main className="canvas">
       <BrowserRouter>
        <Switch>
            <Route path="/tag">
              <Tag />
            </Route>
            <Route path="/tx">
              <Transaction />
            </Route>
            <Route path="/block">
              <Block />
            </Route>
            <Route path="/mempool">
              <Mempool />
            </Route>
            <Route path="/error">
              <Error />
            </Route>
            <Route path="/">
              <Home />
            </Route>
        </Switch>
      </BrowserRouter>
    </main>
  );
}

export default Canvas;