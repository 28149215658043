import '../Canvas.css'
import './Block.css'
import GLOBAL from '../Global'
import {RiLoader3Fill} from 'react-icons/ri'
import {useState, useEffect} from 'react'
import {MdNavigateNext, MdNavigateBefore} from 'react-icons/md'
import { useHistory } from "react-router-dom";

const blockEndpoint = process.env.REACT_APP_MOCHIMO_API_HTTP_ADDRESS + "/bx/v2/block";
const transactionEndpoint = process.env.REACT_APP_MOCHIMO_API_HTTP_ADDRESS + "/bx/v2/txs";
const transactionTableSize = 25;

function Transaction() {

    const blockNumber = window.location.href.substring(window.location.href.lastIndexOf('/') + 1);
    const [block, setBlock] = useState({});
    const [transactions, setTransactions] = useState({transactions: [], lastPageId: null});
    const [txRange, setTxRange] = useState({start: 0, end: transactionTableSize});

    const history = useHistory();
   
    const loadBlock = () => {
        fetch(blockEndpoint + "/" + blockNumber)
        .then((response) => response.json())
        .then((data) => {
            if(data.error){
                console.warn(data);
                history.push({
                    pathname: '/error',
                    state: { msg: "Block not found" }
                });
            }else{
                setBlock(data)
                document.getElementById('block-load-icon').style.display = "none";
                var elements = document.getElementsByClassName('block');
                for(var i = 0;i < elements.length;i++) {
                    var elem = elements[i];
                    elem.style.display = 'inherit'
                }
            }
        }).catch((e) => {console.error(e)});
    }

    const loadTransactions = () => {
        if(transactions.lastPageId !== "end" && transactions.transactions.length < txRange.end) {
            fetch(transactionEndpoint + "?height=" + blockNumber + (transactions.lastPageId ? "&afterPageId=" + transactions.lastPageId : ""))
            .then((response) => response.json())
            .then((data) => {
                if(data.error)
                    console.warn(data);
                else
                    setTransactions({transactions: transactions.transactions.concat(data.transactions), lastPageId: data.pageSize >= data.maxPageSize ? data.pageId : "end"})
            }).catch((e) => {console.error(e)});
        }
    }

    const nextTxRange = () => {
        if(txRange.end >= transactions.transactions.length && transactions.lastPageId === "end")
            return;
        let newTxRange = {start: txRange.end, end: txRange.end + transactionTableSize};
        setTxRange(newTxRange)
        txRange.start = newTxRange.start;
        txRange.end = newTxRange.end;
        loadTransactions();
    }
    
    const previousTxRange = () => {
        if(txRange.start <= 0)
            return;
        setTxRange({start: txRange.start - transactionTableSize, end: txRange.start})
    }

    const partUnixTime = (UNIX_timestamp) => {
            var date = new Date(UNIX_timestamp * 1000);
            return date.toUTCString();
    }
    
    useEffect(() => {
        setTimeout(loadBlock, GLOBAL.LOADER_DELAY);
        loadTransactions();
        return () => {
            }
    }, []);

    return (
        <>
        <div id='block-load-icon' className="loader-container">
            <RiLoader3Fill /> 
        </div>
        <div className="canvas block" style={{display: 'none'}}>
            <div style={{width: '100%', display: 'flex'}}>
                <div className="block-header" style={{marginBottom: '2px'}}>
                    <label className="block-font-family" style={{fontWeight: 'bold', fontSize: '20px', marginRight: '5px'}}>Block  </label>
                    <label className="block-font-family" style={{fontWeight: 'lighter', fontSize: '19px', marginRight: '5px'}}>
                        #{blockNumber}
                        <label style={{marginLeft: '10px', fontSize: '15px', borderRadius: '10px', backgroundColor: 'gray', padding: '0px 7px 0px 7px'}}>{"0x" + parseInt(blockNumber).toString(16) + ""}</label>
                    </label>
                </div>
            </div>
            <div className="card-pair">
                <div className="card" >
                    <div className="card-title">
                        <label>Overview</label>
                    </div>
                    <hr className="card-spliter" />
                    <input className='block-input-clipboard' id="block-balance-store" type="text" readOnly/>
                    <div className="card-row">
                        <label className="card-row-key">Hash:</label>
                        <label className="card-row-value">
                            {block.bhash}
                        </label>
                    </div>
                    <div className="card-row">
                        <label className="card-row-key">Phash:</label>
                        <label className="card-row-value">
                            {block.phash}
                        </label>
                    </div>
                    <div className="card-row">
                        <label className="card-row-key">MRoot:</label>
                        <label className="card-row-value">
                            {block.mroot}
                        </label>
                    </div>
                    <div className="card-row">
                        <label className="card-row-key">Tx Count:</label>
                        <label className="card-row-value">
                            {block.txCount}
                        </label>
                    </div>
                    <div className="card-row">
                        <label className="card-row-key">Min Fee/Tx:</label>
                        <label className="card-row-value">
                            {block.txFee ? (block.txFee/1000000000).toFixed(9) + " MCM" : ""}
                        </label>
                    </div>
                </div>
                <div className="card">
                    <div className="card-title"> 
                        <label>Miner</label>
                    </div>
                    <hr className="card-spliter" />
                    <div className="card-row">
                        <label className="card-row-key">Diff:</label>
                        <label className="card-row-value">
                            {block.diff}
                        </label>
                    </div>
                    <div className="card-row">
                        <label className="card-row-key">Time:</label>
                        <label className="card-row-value">
                            {block.stime ? partUnixTime(block.stime): ""}
                        </label>
                    </div>
                    <div className="card-row">
                        <label className="card-row-key">Public key:</label>
                        <label className="card-row-value">
                            {block.minerAddress ? block.minerAddress.publicKeyId.substring(0, 64) : "--"}
                        </label>
                    </div>
                    <div className="card-row">
                        <label className="card-row-key">Reward:</label>
                        <label className="card-row-value">
                            {block.reward ? (block.reward/1000000000).toFixed(9) + " MCM" : ""}
                        </label>
                    </div>
                    <div className="card-row">
                        <label className="card-row-key">Haiku:</label>
                        <label className="card-row-value">
                            <i>{block.haiku ? block.haiku : "--"}</i>
                        </label>
                    </div>
                </div>
            </div>
            <div style={{flex: '1'}}>
                <div className="card" style={{width: 'auto', visibility: transactions && transactions.transactions && transactions.transactions.length > 0 ? 'initial' : 'hidden'}}>
                    <table className="card-table">
                        <thead>
                            <tr>
                                <th>Txid</th>
                                <th>Source</th>
                                <th>Destination</th>
                                <th>Value</th>
                            </tr>
                        </thead>
                        <tbody>
                            {transactions && transactions.transactions.slice(txRange.start, txRange.end).map((tx) => (
                                <tr key={tx.height + tx.txid} className="card-spliter">
                                    <td><a href={"/tx/" + tx.txid} className="navigation-link" style={{fontWeight: 'lighter'}}>{tx.txid.substring(0, 24)}</a></td>
                                    <td>{tx.source.tag ? <a href={"/tag/" + tx.source.tag} className="navigation-link" style={{fontWeight: 'lighter'}}>{tx.source.tag}</a> : GLOBAL.PUBLIC_KEY_ID_HEADER + tx.source.publicKeyId.substring(0, 24 - GLOBAL.PUBLIC_KEY_ID_HEADER.length)}</td>
                                    <td>{tx.destinations.length > 1 ? ("MTX (" + tx.destinations.length + ")") :
                                        (tx.destinations[0].address.tag ? <a href={"/tag/" + tx.destinations[0].address.tag} className="navigation-link" style={{fontWeight: 'lighter'}}>{tx.destinations[0].address.tag}</a> : GLOBAL.PUBLIC_KEY_ID_HEADER + tx.destinations[0].address.publicKeyId.substring(0, 24 - GLOBAL.PUBLIC_KEY_ID_HEADER.length) )}
                                    </td>
                                    <td style={{textAlign: 'right'}}>{((tx.totalSendAmount + tx.feeAmount)/1000000000).toFixed(9) + " MCM"}</td>
                                </tr>
                                ))
                            }
                        </tbody>
                    </table>
                    <div style={{width: '100%', height: '70px', display: 'grid', margin: '30px 0px 0px 0px'}}>
                        <div style={{display: 'flex', flexDirection: 'column'}}>
                            <div style={{display: 'flex', justifyContent: 'center'}} >
                                <div className="block-icon card-table-navigation-btn" onClick={previousTxRange}>
                                    <MdNavigateBefore size={30} style={{opacity: 1}}/>
                                </div>
                                <div style={{margin: '5px 20px 0px 20px'}}>{txRange.start + (transactions.transactions.length <= 0 ? 0 : 1)} to {Math.min(transactions.transactions.length, txRange.end)}</div>
                                <div className="block-icon card-table-navigation-btn" onClick={nextTxRange}>
                                    <MdNavigateNext size={30} style={{opacity: 1}}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    );
}

export default Transaction;
