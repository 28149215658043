import './App.css';
import Header from './Header';
import Canvas from './Canvas';
import Footer from './Footer';


function App() {
  return (
    <>
      <Header />
      <Canvas />
      <Footer />
    </>
  );
}

export default App;
