import '../Canvas.css'
import './Home.css'
import GLOBAL from '../Global'
import {useState, useEffect} from 'react'
import { useHistory } from "react-router-dom";
import {RiLoader3Fill} from 'react-icons/ri'

const blockEndpoint = process.env.REACT_APP_MOCHIMO_API_HTTP_ADDRESS + "/bx/v2/blocks";
const transactionEndpoint = process.env.REACT_APP_MOCHIMO_API_HTTP_ADDRESS + "/bx/v2/txs";
const mempoolEndpoint = process.env.REACT_APP_MOCHIMO_API_HTTP_ADDRESS + "/node/v1/lcblock";

function Home() {

    const [recentBlocks, setRecentBlocks] = useState(null);
    const [recentTransactions, setRecentTransactions] = useState(null);
    const [mempool, setMempool] = useState(null);
  
    const history = useHistory();

    const loadRecentBlocks = () => {
        fetch(blockEndpoint)
        .then((response) => response.json())
        .then((data) => {
            if(data.error){
                console.warn(data);
                history.push({
                    pathname: '/error',
                    state: { msg: "Error while loading recent blocks" }
                });
            }else{
                setRecentBlocks(data.blocks)
                document.getElementById('home-network-load-icon').style.display = "none";
                document.getElementById('home-latest-blocks-load-icon').style.display = "none";

                var tables = document.getElementsByClassName("card-table")
                for(var i = 0;i < tables.length;i++) {
                    tables[i].style.display = "table";
                }
                
                for(var i = 0;i<data.blocks.length;i++) {
                    let block = data.blocks[i];
                    if(!block.mroot.startsWith("0000000000000000000000000000000000000000000000000000")){
                        loadRecentTransactions(block.height);
                        break;
                    }
                }
            }
        }).catch((e) => {console.error(e)});;
    }

    const loadRecentTransactions = (height) => {
        if(!height)
            return;
        fetch(transactionEndpoint + "?height=" + height)
        .then((response) => response.json())
        .then((data) => {
            if(data.error){
                console.warn(data);
                history.push({
                    pathname: '/error',
                    state: { msg: "Error while loading recent transactions" }
                });
            }else{
                setRecentTransactions(data.transactions)
                document.getElementById('home-latest-transactions-load-icon').style.display = "none";
            }
        }).catch((e) => {console.error(e)});;
    }

    const loadMempool = () => {
        fetch(mempoolEndpoint)
        .then((response) => response.json())
        .then((data) => {
            if(data.error){
                console.warn(data);
                history.push({
                    pathname: '/error',
                    state: { msg: "Error while loading mempool" }
                });
            }else{
                setMempool(data)
                document.getElementById('home-mempool-load-icon').style.display = "none";
                /*var elements = document.getElementsByClassName('home');
                for(var i = 0;i < elements.length;i++) {
                    var elem = elements[i];
                    elem.style.display = 'inherit'
                }*/
            }
        }).catch((e) => {console.error(e)});;
    }

    const humanDuration = (seconds) => {
        let min = Math.floor(seconds / 60);
        let sec = Math.floor(seconds - min * 60);
        return min + "m " + sec + "s"
    }

    const computeBlockStat = (indicator) => {
        if(!recentBlocks || recentBlocks.length == 0)
            return "--"
            switch(indicator) {
                case "hashrate": {
                    let sumHPS = 0;
                    for(var i = 0;i < recentBlocks.length;i++) {
                        let block = recentBlocks[i];
                        var haikuToSolution = Math.pow(2, Math.max(block.diff - 1, 0));
                        var elapsed = block.stime - block.t0;
                        var hps = haikuToSolution/elapsed;
                        sumHPS += block.txCount > 0 ? hps : 0;
                    }
                    
                    let globalHPS = sumHPS/recentBlocks.length;
                    let globalMHPS = globalHPS/1000000;
                    return globalMHPS.toFixed(2) + " MH/s";
                }
                break;

                case "txperblock": {
                    let sumTX = 0;
                    for(var i = 0;i < recentBlocks.length;i++) {
                        let block = recentBlocks[i];
                         sumTX += block.txCount;
                    }
                    
                    let globalTX = sumTX/recentBlocks.length;
                     return globalTX.toFixed(0);
                }
                break;

                case "tps": {
                    let sumTX = 0;
                    let sumTime = 0;
                    for(var i = 0;i < recentBlocks.length;i++) {
                        let block = recentBlocks[i];
                         sumTX += block.txCount;
                         sumTime += block.stime - block.t0;
                    }
                    
                    let globalTPS = sumTX/sumTime;
                     return globalTPS.toFixed(2);
                }
                break;

                case "solvetime": {
                    let sumSolveTime = 0;
                    for(var i = 0;i < recentBlocks.length;i++) {
                        let block = recentBlocks[i];
                        sumSolveTime += block.stime - block.t0;
                    }
                    
                    let globalSolveTime = sumSolveTime/recentBlocks.length;
                    return humanDuration(globalSolveTime);
                }
                break;

                default:
                return "Unhandled indicator " + indicator;
            }
        
    }

    const computeDiff = (indicator) => {
        if(!recentBlocks || recentBlocks.length == 0)
            return "--"

        switch(indicator) {
            case "avg":{
                let sumDiff = 0;
                for(var i = 0;i < recentBlocks.length;i++) {
                    let block = recentBlocks[i];
                    sumDiff += block.diff;
                }
                
                let globalDiff = sumDiff/recentBlocks.length;
                return globalDiff.toFixed(0);
            }
            break;

            case "min":{
                let min = null;
                for(var i = 0;i < recentBlocks.length;i++) {
                    let block = recentBlocks[i];
                    if(!min || min > block.diff)
                        min = block.diff;
                }
                
                return min;
            }
            break;

            case "max":{
                let max = null;
                for(var i = 0;i < recentBlocks.length;i++) {
                    let block = recentBlocks[i];
                    if(!max || max < block.diff)
                        max = block.diff;
                }
                
                return max;
            }
            break;

            default:
                return "Unhandled indicator " + indicator;
        }        
    }
   

    useEffect(() => {

        const loadAll = () => {
            loadRecentBlocks();
            loadMempool();
        } 
       
        setTimeout(loadAll, GLOBAL.LOADER_DELAY);
      
        const updateAll = setInterval(loadAll, 20000);
  
        return () => clearInterval(updateAll);
    }, []);


    return (
        <>
        
        <div className="canvas home">
                <div className="card-pair">
                    <div className="card home-card">
                        <label className="card-title">Network</label>
                        <hr className="card-spliter" />
                        <div id='home-network-load-icon' className="loader-container home-card-loader">
                            <RiLoader3Fill /> 
                        </div>
                        <div className="card-row home-card-row" style={{marginTop: '30px'}}>
                            <div className="card-row-value">
                                <div >
                                    <label style={{fontSize: '60px'}}>{computeBlockStat("hashrate")}</label>
                                </div>
                            </div>
                        </div>
                        <div className="card-row home-card-row">
                            <div className="card-row-value">
                                <div>
                                    <label>Difficulty  </label>
                                    <label>{computeDiff("avg")}  </label>
                                    <label style={{fontSize: '15px'}}>min {computeDiff("min")}</label>
                                    <label style={{fontSize: '15px'}}>  max {computeDiff("max")}</label>
                                </div>
                            </div>
                        </div>
                        <div className="card-row home-card-row">
                            <div className="card-row-value">
                                <div>
                                    <label>Solve time  </label>
                                    <label>{computeBlockStat("solvetime")}</label>
                                </div>
                            </div>
                        </div>
                        <div className="card-row home-card-row">
                            <div className="card-row-value">
                                <div>
                                    <label>{computeBlockStat("txperblock")}</label>
                                    <label> tx/block</label>
                                </div>
                            </div>
                        </div>
                        <div className="card-row home-card-row">
                            <div className="card-row-value">
                                <div>
                                    <label>{computeBlockStat("tps")}</label>
                                    <label> tx/s</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card home-card">
                        <label className="card-title">
                            <a href="/mempool" className="navigation-link">Mempool</a>
                        </label>
                        <hr className="card-spliter" />
                        <div id='home-mempool-load-icon' className="loader-container home-card-loader">
                            <RiLoader3Fill /> 
                        </div>
                        <div className="card-row home-card-row" style={{marginTop: '30px'}}>
                            <div className="card-row-value">
                                <div>
                                    {mempool &&
                                        <>
                                            <label>Block  </label>
                                            <label style={{fontSize: '60px'}}>{mempool.height}</label>
                                            <label style={{fontSize: '15px'}}>  diff {mempool.difficulty}</label>
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="card-row home-card-row">
                            <div className="card-row-value">
                                <div>
                                    {mempool &&
                                        <>
                                            <label>{mempool.totalTransactionCount}</label>
                                            <label>  {mempool.totalTransactionCount > 1 ? "transactions pending" : "transaction pending"}</label>
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="card-row home-card-row">
                            <div className="card-row-value">
                                <div>
                                    {mempool &&
                                        <>
                                            <label>{(mempool.totalTransactionValue/1000000000).toFixed(2)}</label>
                                            <label> MCM pending</label>
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="card-row home-card-row">
                            <div className="card-row-value">
                                <div>
                                    {mempool &&
                                        <>
                                            <label>Value/tx </label>
                                            <label>{(mempool.totalTransactionValue/mempool.totalTransactionCount/1000000000).toFixed(2)}</label>
                                            <label> MCM</label>
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="card-row home-card-row">
                            <div className="card-row-value">
                                <div>
                                    {mempool &&
                                        <>
                                            <label>Fee/tx </label>
                                            <label>{Math.max(500, mempool.averageFeePerTransaction.toFixed(0))}</label>
                                            <label> nMCM</label>
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-pair">
                    <div className="card home-card">
                        <label className="card-title">Latest Blocks</label>
                        <hr className="card-spliter" />
                        <div id='home-latest-blocks-load-icon' className="loader-container home-card-loader">
                            <RiLoader3Fill /> 
                        </div>
                        <table className="card-table" style={{marginTop: '30px', display: 'none'}}>
                            <thead>
                                <tr>
                                    <th>Block</th>
                                    <th>Diff</th>
                                    <th>Age</th>
                                    <th>Tx</th>
                                </tr>
                            </thead>
                            <tbody>
                                {recentBlocks && recentBlocks.slice(0, 10).map((block) => (
                                    <tr key={block.height + block.bhash} className="card-spliter">
                                        <td><a href={"/block/" + block.height} className="navigation-link" style={{fontWeight: 'lighter'}}>{block.height}</a></td>
                                        <td>{block.diff}</td>
                                        <td>{humanDuration(new Date().getTime()/1000 - block.stime)}</td>
                                        <td>{block.txCount}</td>
                                    </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                    <div className="card home-card">
                        <label className="card-title">Latest Transactions</label>
                        <hr className="card-spliter" />
                        <div id='home-latest-transactions-load-icon' className="loader-container home-card-loader">
                            <RiLoader3Fill /> 
                        </div>
                        <table className="card-table" style={{marginTop: '30px', display: 'none'}}>
                            <thead>
                                <tr>
                                    <th>Block</th>
                                    <th>Txid</th>
                                    <th>Value</th>
                                </tr>
                            </thead>
                            <tbody>
                                {recentTransactions && recentTransactions.slice(0, 10).map((tx) => (
                                    <tr key={tx.height + tx.txid} className="card-spliter">
                                        <td><a href={"/block/" + tx.height} className="navigation-link" style={{fontWeight: 'lighter'}}>{tx.height}</a></td>
                                        <td><a href={"/tx/" + tx.txid} className="navigation-link" style={{fontWeight: 'lighter'}}>{tx.txid.substring(0, 24)}</a></td>
                                        <td style={{textAlign: 'right'}}>{((tx.totalSendAmount + tx.feeAmount)/1000000000).toFixed(9)} MCM</td>
                                    </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
        </div>
        </>
    );
}

export default Home;
