import '../Canvas.css'
import './Transaction.css'
import GLOBAL from '../Global'
import {useState, useEffect} from 'react'
import {RiLoader3Fill} from 'react-icons/ri'
import { useHistory } from "react-router-dom";

const transactionEndpoint = process.env.REACT_APP_MOCHIMO_API_HTTP_ADDRESS + "/bx/v2/txs";
const blockEndpoint = process.env.REACT_APP_MOCHIMO_API_HTTP_ADDRESS + "/api/v2/bc/state";

function Transaction(props) {

    const [txid, setTxid] = useState(props && props.transaction ? props.transaction.txid : window.location.href.substring(window.location.href.lastIndexOf('/') + 1));
    const [transactions, setTransactions] = useState({requested: false, transactions: props && props.transaction ? [props.transaction] : []});
    const [blockNumber, setBlockNumber] = useState(props ? props.blockNumber : null);

    const transaction = transactions.requested && transactions.transactions.length > 0 ?  transactions.transactions[0] : null;
    
    const history = useHistory();

    const loadTransaction = () => {
        fetch(transactionEndpoint + "?txid=" + txid)
        .then((response) => response.json())
        .then((data) => {
            console.log(data)
            if(data.error || data.pageSize <= 0){
                console.warn(data);
                history.push({
                    pathname: '/error',
                    state: { msg: data.error ? "Invalid txid" : "Transaction not found" }
                  })
            }else{
                //for(var i=0;i<200;i++)
                //data.transactions[0].destinations.push(data.transactions[0].destinations[0]);
                setTransactions({requested: true, transactions: data && data.transactions && data.transactions.length > 0 ? data.transactions : []});
                document.getElementById('tx-load-icon').style.display = "none";
                var elements = document.getElementsByClassName('transaction');
                for(var i = 0;i < elements.length;i++) {
                    var elem = elements[i];
                    elem.style.display = 'inherit'
                }
            }
        }).catch((e) => {console.error(e)});
    }

    const loadBlockNumber = () => {
        fetch(blockEndpoint)
        .then((response) => response.json())
        .then((data) => {
            setBlockNumber(data && data.height ? data.height : null)
        }).catch((e) => {console.error(e)});
    }

    const showMTX = () => {
       const mtxs = document.getElementsByClassName("mtx-row");
       for(var i=0;i<mtxs.length;i++)
       mtxs[i].style.display = 'block';

       document.getElementById("mtx-show-btn").style.display = 'none';
    }
  
    const copyToClipboard = (e) =>{
        const copyText = document.getElementById("txid-store")
        copyText.style.display = 'initial';
        copyText.select();
        copyText.setSelectionRange(0, 99999);
        document.execCommand("copy");

        copyText.style.display = 'none';

        var tooltip = document.getElementById("ctc");
        tooltip.innerHTML = "Copied !";

        e = e || window.event;
        if (typeof e.stopPropagation != "undefined") {
            e.stopPropagation();
        } else {
            e.cancelBubble = true;
        }
    }

    const outCopyToClipboard  = () => {
        var tooltip = document.getElementById("ctc");
        tooltip.innerHTML = "Copy tag";
    }

    useEffect(() => {
        setTimeout(loadTransaction, GLOBAL.LOADER_DELAY);
        loadBlockNumber();
    
        return () => {
            }
    }, []);

    return (
        <>
        <div id='tx-load-icon' className="loader-container">
            <RiLoader3Fill />  
        </div>
        <div className="canvas transaction" style={{display: 'none'}}>
            <div className="card transaction-card">
                <div style={{display: 'flex', flexDirection: 'column', width: '100%', height: '100%'}}>
                    <div className="card-title">
                        <label>Transaction</label>
                    </div>
                    <hr className="card-spliter" />
                    <div className="card-row">
                        <label className="card-row-key">Txid:</label>
                        <label className="card-row-value">
                            {txid &&
                                txid
                            }
                        </label>
                        <input className='transaction-input-clipboard' id="txid-store" type="text" defaultValue={txid} readOnly/>
                    </div>
                    <hr className="transaction-container-split"/>
                    <div className="card-row">
                        <label className="card-row-key">Block:</label>
                        <label className="card-row-value">
                            <div>
                                {transaction && blockNumber &&
                                    <a href={"/block/" + blockNumber} className="navigation-link">{transaction.height}</a>
                                }
                                
                                    {transaction && blockNumber &&
                                        (() => {
                                            const elapsed = blockNumber - transaction.height;
                                            var status;
                                            if(elapsed == 0)
                                                status = "Just now";
                                            else if(elapsed == 1)
                                                status = elapsed + " block ago";
                                            else
                                                status = elapsed + " blocks ago"
                                            
                                            return <label className="transaction-block-confirmation">{status}</label>;
                                        })()
                                    }
                                
                            </div>
                        </label>
                    </div>
                    <hr className="card-spliter"/>
                    <div className="card-row">
                        <label className="card-row-key">Source:</label>
                        <label className="card-row-value">
                            {transaction &&
                                (transaction.source.tag ? <a href={"/tag/" + transaction.source.tag} className="navigation-link">{transaction.source.tag}</a>
                                 : GLOBAL.PUBLIC_KEY_ID_HEADER + transaction.source.publicKeyId.substring(0, 64))
                            }
                        </label>
                    </div>
                    <hr className="card-spliter"/>
                    <div className="card-row">
                        <label className="card-row-key">Change:</label>
                        <label className="card-row-value">
                            {transaction &&
                                (transaction.change.tag ? <a href={"/tag/" + transaction.change.tag} className="navigation-link">{transaction.change.tag}</a>
                                 : GLOBAL.PUBLIC_KEY_ID_HEADER + transaction.change.publicKeyId.substring(0, 64))
                            }
                        </label>
                    </div>
                    <hr className="card-spliter"/>
                    <div className="card-row" style={{height: 'auto'}}>
                        {transaction && 
                            (transaction.destinations.length <= 1 ?
                                <>
                                    <label className="card-row-key">Destination:</label>
                                    <label className="card-row-value">
                                        <div>
                                            {transaction.destinations[0].address.tag ?
                                              <a href={"/tag/" + transaction.destinations[0].address.tag} className="navigation-link">{transaction.destinations[0].address.tag}</a>
                                             : (GLOBAL.PUBLIC_KEY_ID_HEADER + transaction.destinations[0].address.publicKeyId.substring(0, 64))
                                            }
                                        </div>
                                        <div style={{marginTop: '5px'}}>
                                            {" ⇨ " + (transaction.destinations[0].amount/1000000000).toFixed(9) + " MCM"}
                                        </div>
                                    </label>
                                </>
                                :
                                <>
                                    <label className="card-row-key" style={{display: 'block', marginTop: '15px'}}>{transaction.destinations.length} destinations:</label>
                                    <label className="card-row-value">
                                        <div style={{marginTop: '15px'}}/>
                                        <div id="mtx-show-btn" className="card-row-value" style={{width: '100%', height: '50px', backgroundColor: '#444', textAlign: 'center'}} onClick={showMTX}>
                                            Click to Show
                                        </div>
                                        {transaction.destinations.map((d) => {
                                                return (
                                                        <div className="mtx-row">
                                                            <a href={"/tag/" + d.address.tag} className="navigation-link">{d.address.tag}</a>
                                                            {" ⇨ " + (d.amount/1000000000).toFixed(9) + " MCM"}
                                                        </div>
                                                )
                                            })
                                        }
                                        
                                        <div style={{marginTop: '15px'}}/>
                                    </label>
                                </>
                            )
                        }
                    </div>
                    <hr className="card-spliter"/>
                    <div className="card-row">
                        <label className="card-row-key">Transaction fee:</label>
                        <label className="card-row-value">
                            {transaction &&
                                (transaction.feeAmount/1000000000).toFixed(9) + " MCM"
                            }
                        </label>
                    </div>
                    <hr className="card-spliter"/>
                    <div className="card-row">
                        <label className="card-row-key">Change amount:</label>
                        <label className="card-row-value">
                            {transaction &&
                                (transaction.changeAmount/1000000000).toFixed(9) + " MCM"
                            }
                        </label>
                    </div>
                </div>
            </div>
            <div style={{width: '100%', flex: '1'}}>
            </div>
        </div>
        </>
    );
}

export default Transaction;
