import './Footer.css'
import background from "./resources/img/mochimo-pq-logo.svg";
import {SiDiscord} from 'react-icons/si'
import {FaGithub} from 'react-icons/fa'


function Footer() {
  return (
    <footer className="footer">
        <div style={{width: '100%', height: '100%', display: 'flex', flexDirection: 'columns'}}>
          <div style={{float: "left", width: '300px', justifyContent: "center", margin: '7px 0px 0px 20px'}}>
            <div style={{marginTop: '0px'}}>
              <a target="_blank" rel="noopener noreferrer" href="https://mochimo.org" className="navigation-link">Mochimo Website</a>
            </div>
            <div style={{marginTop: '11px'}}>
              <a target="_blank" rel="noopener noreferrer" href="https://www.coingecko.com/en/coins/mochimo" style={{color: '#8BC53F', fontWeight: 'lighter', textDecoration: "none"}}>Visit Coingecko</a>
            </div>
          </div>
          <div style={{display: "grid"}}>
            <div style={{cursor: 'pointer', marginTop: '5px'}}>
              <a target="_blank" rel="noopener noreferrer" href=" https://discord.gg/7ma6Bk2" style={{fontWeight: 'lighter', textDecoration: "none", color: "white"}}>
                <div style={{float: "left", marginTop: "5px"}}>
                    <SiDiscord width="30px" height="50px"/>
                </div>
                <div style={{float: "left", margin: "3px 0px 0px 5px", cursor: 'pointer'}}>
                  Join Discord
                </div>
              </a>
            </div>
            <div style={{cursor: 'pointer', marginTop: '-5px'}}>
              <a target="_blank" rel="noopener noreferrer" href="https://github.com/mochimodev/mochimo" style={{fontWeight: 'lighter', textDecoration: "none", color: "white"}}>
                <div style={{float: "left", margin: "0px 5px 0px 0px"}}>
                    <FaGithub width="30px" height="50px"/>
                </div>
                <div style={{float: "left", margin: "-1px 0px 0px 0px", cursor: 'pointer'}}>
                  Contribute on Github
                </div>
              </a>
            </div>           
          </div>
        </div>
    </footer>
  );
}

export default Footer;