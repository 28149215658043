import './Header.css'
import SearchInput from './SearchInput'
import {ReactComponent  as MCMLogo} from "./resources/img/mochimo-pq-logo-white.svg";
import {useState, useEffect} from 'react'

const coingeckoApiEndpoint = "https://api.coingecko.com/api/v3/simple/price?ids=mochimo&vs_currencies=btc%2Ceth%2Cusd&include_market_cap=true&include_24hr_vol=true&include_24hr_change=true";

function Header() {

    const formatValue = (ccy, data) => {
        const lccy = ccy.toLowerCase();
        var price = [];
        if(data[lccy])
            price['price'] = data[lccy];
        else
        price['price'] = null;
        
        if(data[lccy + "_24h_change"])
            price['chg'] = data[lccy + "_24h_change"].toFixed(2);
        else
            price['chg'] = null;
        return price;
    }

    const loadPriceData = () => {
        fetch(coingeckoApiEndpoint)
        .then((response) => response.json())
        .then((data) => {
            const usdValue = formatValue('usd', data.mochimo);
            if(document.getElementById("mcm-usd-price"))
                document.getElementById("mcm-usd-price").innerHTML =  "$ " + usdValue['price'];
            if(document.getElementById("mcm-usd-quote"))
                document.getElementById("mcm-usd-quote").innerHTML = usdValue['price'];
            if(document.getElementById("mcm-usd-change") && usdValue['chg']) {
                document.getElementById("mcm-usd-change").innerHTML =  " (" + usdValue['chg'] + "%" + ")";
                if(usdValue['chg'] < 0)
                    document.getElementById("mcm-usd-change").style.color = 'red'
                else
                    document.getElementById("mcm-usd-change").style.color = 'green'
            }

            const btcValue = formatValue('btc', data.mochimo);
            if(document.getElementById("mcm-btc-price"))
                document.getElementById("mcm-btc-price").innerHTML =  "₿ " + btcValue['price'] + " ";
            if(document.getElementById("mcm-btc-quote"))
                document.getElementById("mcm-btc-quote").innerHTML = btcValue['price'];
            if(document.getElementById("mcm-btc-change") && btcValue['chg']) {
                document.getElementById("mcm-btc-change").innerHTML =  " (" + btcValue['chg'] + "%" + ")";
                if(btcValue['chg'] < 0)
                    document.getElementById("mcm-btc-change").style.color = 'red'
                else
                    document.getElementById("mcm-btc-change").style.color = 'green'
            }

            const ethValue = formatValue('eth', data.mochimo);
            if(document.getElementById("mcm-eth-price"))
                document.getElementById("mcm-eth-price").innerHTML =  "Ξ " + ethValue['price'] + " ";
            if(document.getElementById("mcm-eth-quote"))
                document.getElementById("mcm-eth-quote").innerHTML = ethValue['price'];
            if(document.getElementById("mcm-eth-change") && ethValue['chg']) {
                document.getElementById("mcm-eth-change").innerHTML =  "(" + ethValue['chg'] + "%" + ")";
                if(ethValue['chg'] < 0)
                    document.getElementById("mcm-eth-change").style.color = 'red'
                else
                    document.getElementById("mcm-eth-change").style.color = 'green'
            }
        });
    }

    useEffect(() => {
      loadPriceData();
     
      const updateAccounts = setInterval(loadPriceData, 60000);
  
      return () => clearInterval(loadPriceData);
    }, []);

    return (
        <div className="header">
            <div style={{display: "block"}}>
                <div style={{float: "left", margin: "5px 5px 0px 5px"}}>
                    <a href="/">
                        <MCMLogo width="200px" height="50px"/>
                    </a>
                </div>
                <div style={{float: "right", margin: "5px 15px 5px 5px"}}>
                    {/*<button>BUTTON</button>*/}
                </div>
            </div>
            <div className="header-bottom-container">
                <div className="header-price-container" style={{float: 'left'}}>
                    <label style={{marginLeft: '10px'}}>MCM:</label>
                    <label id='mcm-usd-price'> -- </label><label id='mcm-usd-change'> -- </label>
                    <label id='mcm-usd-quote' className='header-price-input-clipboard'></label>
                    <label>|</label>                 
                    <label id='mcm-btc-price'> -- </label><label id='mcm-btc-change'> -- </label>
                    <label id='mcm-btc-quote' className='header-price-input-clipboard'></label>
                    <label>|</label>
                    <label id='mcm-eth-price'> -- </label><label id='mcm-eth-change'> -- </label>
                    <label id='mcm-eth-quote' className='header-price-input-clipboard'></label>
                </div>
                <div style={{float: 'right', marginRight: '17px'}}>
                <SearchInput />
                </div>
            </div>
        </div>
    );
}

export default Header;