import '../Canvas.css'
import './Error.css'
import {useLocation} from "react-router-dom";
import {useState, useEffect} from 'react'
import {BiCommentError} from 'react-icons/bi'


function Error(props) {
    
    const location = useLocation();
    console.log(location)
    let msg = "An error has occurred";

    if(props && props.msg)
        msg = props.msg;
    else if(location.state && location.state.msg)
        msg = location.state.msg;
    
    useEffect(() => {
    
        return () => {
            }
    }, []);

    return (
        <div className="canvas error">
            <div className="error-container">
                <div style={{width: '100%'}}>
                    <BiCommentError size={100}/>
                </div>
                <div className="error-msg">
                    {msg}
                </div>
            </div>
        </div>
    );
}

export default Error;
