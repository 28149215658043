import '../Canvas.css'
import './Mempool.css'
import GLOBAL from '../Global'
import {RiLoader3Fill} from 'react-icons/ri'
import {useState, useEffect, useRef} from 'react'
import {MdNavigateNext, MdNavigateBefore} from 'react-icons/md'
import { useHistory } from "react-router-dom";

const mempoolEndpoint = process.env.REACT_APP_MOCHIMO_API_HTTP_ADDRESS + "/node/v1/hcblock";
const transactionTableSize = 25;

function Mempool() {
   
    const [mempool, setMempool] = useState({header: null, transactions: []});
    const [txRange, setTxRange] = useState({start: 0, end: transactionTableSize});
    const memPoolRef = useRef({header: null, transactions: [], lastPageId: null});
    
    const history = useHistory();
   
    const loadMempool = () => {
        let lastPageId = memPoolRef.current.lastPageId;
        console.log('Request mempool after page ' + lastPageId)
        console.log(memPoolRef.current)
        fetch(mempoolEndpoint + ( lastPageId ? "?afterPageId=" + lastPageId : ""))
        .then((response) => response.json())
        .then((data) => {

            if(data.error){
                console.warn(data);
                history.push({
                    pathname: '/error',
                    state: { msg: "Mempool request failed" }
                });
            }else{
                if(memPoolRef.current.header && memPoolRef.current.header.height && memPoolRef.current.header.height !== data.height){
                    // change height while loading mempool chunk
                    console.log('Block height changed from ' + memPoolRef.current.header.height + ' to ' + data.height)
                    delete data.transactions;
                    memPoolRef.current.header = data;
                    memPoolRef.current.transactions = [];
                    memPoolRef.current.lastPageId = null;

                    setMempool({header: memPoolRef.current.header, transactions: []});
                    document.getElementById('load-mempool-flag').innerHTML = 'height changed';
                }
                else if(data.transactions.length > 0 ) {

                    const mempoolDict = {};
                    for(var i = 0;i < memPoolRef.current.transactions.length;i++){
                        var tx = memPoolRef.current.transactions[i];
                        mempoolDict[tx.receivedUnixTimestamp + tx.txid] = tx;
                    }

                    const beforeSize = Object.keys(mempoolDict).length;
                    // put new transaction in mempool dictionnary
                    for(var i = 0;i < data.transactions.length;i++) {
                        var tx = data.transactions[i].transaction;
                        tx['receivedDatetime'] = data.transactions[i].receivedDatetime;
                        tx['receivedUnixTimestamp'] = data.transactions[i].receivedUnixTimestamp;
                        mempoolDict[tx.receivedUnixTimestamp + tx.txid] = tx;
                    }
                    
                    const newTransactions = Object.keys(mempoolDict).length - beforeSize;
                    console.log(newTransactions + "/" + data.transactions.length + " new transaction synchronized ")

                    var txs = Object.keys(mempoolDict).map(function(key){
                        return mempoolDict[key];
                    });
                    txs.sort((tx1, tx2) => {return tx2.receivedUnixTimestamp - tx1.receivedUnixTimestamp});

                    delete data.transactions;
                    memPoolRef.current.header = data;
                    memPoolRef.current.lastPageId = data.pageId;
                    memPoolRef.current.transactions = txs;

                    setMempool({header: memPoolRef.current.header, transactions: memPoolRef.current.transactions});
                    
                    if(memPoolRef.current.header && memPoolRef.current.header.totalTransactionCount == memPoolRef.current.transactions.length){
                        console.log('Local mempool is fully synchronized with remote');
                        memPoolRef.current.lastPageId = null
                        document.getElementById('load-mempool-flag').innerHTML = null;
                    }else {
                        /*if(newTransactions == 0) {
                            if(!beforeReceivedUnixTimestamp)
                                console.warn('No transaction synchronized with the head of remote mempool - Keeping timestamp filter')// this should only happend when the number of new tx exceed the size of the page
                            else{
                                console.log('No new transaction - Reseting timestamp filter')
                                memPoolRef.current.lastPageId = null;
                            }
                        }*/
                        document.getElementById('load-mempool-flag').innerHTML = 'load next page';
                    }
                }else{
                    console.log('Last page')
                    memPoolRef.current.lastPageId = null
                    if(memPoolRef.current.header && memPoolRef.current.header.totalTransactionCount == memPoolRef.current.transactions.length){
                        console.log('Local mempool is fully synchronized with remote');
                        document.getElementById('load-mempool-flag').innerHTML = null;
                    }else                 
                        document.getElementById('load-mempool-flag').innerHTML = 'last page';
                }
                
                console.log('Local transaction count: ' + memPoolRef.current.transactions.length + ', remote: ' + memPoolRef.current.header.totalTransactionCount);
                document.getElementById('mempool-load-icon').style.display = "none"
                var elements = document.getElementsByClassName('mempool');
                for(var i = 0;i < elements.length;i++) {
                    var elem = elements[i];
                    elem.style.display = 'inherit'
                }

            }
        }).catch((e) => {console.error(e)});
    }
   
    const partUnixTime = (UNIX_timestamp) => {
            var date = new Date(UNIX_timestamp * 1000);
            return date.toUTCString();
    }

    const nextTxRange = () => {
        if(txRange.end >= mempool.transactions.length)
            return;
        let newTxRange = {start: txRange.end, end: txRange.end + transactionTableSize};
        setTxRange(newTxRange)
        txRange.start = newTxRange.start;
        txRange.end = newTxRange.end;
    }
    
    const previousTxRange = () => {
        if(txRange.start <= 0)
            return;
        setTxRange({start: txRange.start - transactionTableSize, end: txRange.start})
    }

    const humanDuration = (seconds) => {
        let min = Math.floor(seconds / 60);
        let sec = Math.floor(seconds - min * 60);
        return min + "m " + sec + "s"
    }

    const getElapsedColor = (elapsed) => {
        const timeframe = 337.5 * 3;
        //return `rgb(${255 * elapsed/timeframe}, ${255 * timeframe/(elapsed+1)}, ${0})`;
        if(elapsed < 337.5)
            return 'inherit';
        
        if(elapsed < 337.5*2 )
            return '#E16A00'

        return '#B20710';
    }
    const setBlockElapsed = () => {

        if(memPoolRef.current && memPoolRef.current.header && memPoolRef.current.header.t0) {
            // console.log(memPoolRef.current.header)
            const elapsed = new Date().getTime()/1000 - memPoolRef.current.header.t0;
            const color = getElapsedColor(elapsed);  
            document.getElementById('block-elapsed').innerHTML = humanDuration(elapsed);
            if(color)
                document.getElementById('block-elapsed').style.color = color;
        }
    }


    useEffect(() => {
        setTimeout(loadMempool, GLOBAL.LOADER_DELAY);
        
        const pauseTick = 8;
        let counter = 0;
        const adjustedLoadMempool = () => {
            const loadFlag = document.getElementById('load-mempool-flag');

            if((loadFlag && loadFlag.innerHTML) || counter >= pauseTick){
                console.log('Loading mempool page')
                loadMempool();
                counter = 0;
            } else{
                console.log('Paused - ' + counter + '/' + pauseTick)
                counter++; 
            }
        }

        const updateMempool = setInterval(adjustedLoadMempool, 2000);
        const updateBlockElapsed = setInterval(setBlockElapsed, 1000);
  
        return () => {
            clearInterval(updateMempool);
            clearInterval(updateBlockElapsed);
        }
    }, []);

    return (
        <>
        <div id='mempool-load-icon' className="loader-container">
            <RiLoader3Fill />  
        </div>
        <div className="canvas mempool" style={{display: 'none'}}>
            <div id="load-mempool-flag" className="mempool-input-clipboard" />
                <div className="card-pair">
                <div className="card" >
                    <div className="card-title">
                        <label>Block</label>
                    </div>
                    <hr className="card-spliter" />
                    <div className="card-row">
                        <label className="card-row-key">Height:</label>
                        <label className="card-row-value">
                            {mempool && mempool.header && mempool.header.height &&
                                mempool.header.height
                            }
                        </label>
                    </div>
                    <div className="card-row">
                        <label className="card-row-key">Difficulty:</label>
                        <label className="card-row-value">
                            {mempool && mempool.header && mempool.header.difficulty &&
                                mempool.header.difficulty
                            }
                        </label>
                    </div>
                    <div className="card-row">
                        <label className="card-row-key">Elapsed:</label>
                        <label id="block-elapsed" className="card-row-value">
                            {mempool && mempool.header && mempool.header.t0 &&
                                humanDuration(new Date().getTime()/1000 - mempool.header.t0)
                            }
                        </label>
                    </div>
                </div>
                <div className="card">
                    <div className="card-title"> 
                        <label>Transactions</label>
                    </div>
                    <hr className="card-spliter" />
                    <div className="card-row">
                        <label className="card-row-key">Tx pending:</label>
                        <label className="card-row-value">
                            {mempool && mempool.header && mempool.header.totalTransactionCount &&
                                mempool.header.totalTransactionCount
                                + (mempool.header.totalTransactionCount !== mempool.transactions.length ? " (" +  mempool.transactions.length + " synchronized)" : "")
                            }
                        </label>
                    </div>
                    <div className="card-row">
                        <label className="card-row-key">Value pending:</label>
                        <label className="card-row-value">
                            {mempool && mempool.header && mempool.header.totalTransactionValue &&
                                (mempool.header.totalTransactionValue/1000000000).toFixed(9) + " MCM"
                            }
                        </label>
                    </div>
                    <div className="card-row">
                        <label className="card-row-key">Value/tx:</label>
                        <label className="card-row-value">
                            {mempool && mempool.header && mempool.header.totalTransactionValue &&
                                (mempool.header.totalTransactionValue/mempool.header.totalTransactionCount/1000000000).toFixed(9) + " MCM"
                            }
                        </label>
                    </div>
                </div>
            </div>
            <div style={{flex: '1'}}>
                <div className="card" style={{width: 'auto', visibility: mempool && mempool.transactions && mempool.transactions.length > 0 ? 'initial' : 'hidden'}}>
                    <table className="card-table">
                        <thead>
                            <tr>
                                <th>Age</th>
                                <th>Source</th>
                                <th>Destination</th>
                                <th>Value</th>
                            </tr>
                        </thead>
                        <tbody>
                            {mempool && mempool.transactions/*.slice(txRange.start, txRange.end)*/.map((tx) => (
                                <tr key={tx.txid} className="card-spliter">
                                    <td>{humanDuration(new Date().getTime()/1000 - tx.receivedUnixTimestamp)}</td>
                                    <td>{tx.source.tag ? <a href={"/tag/" + tx.source.tag} className="navigation-link" style={{fontWeight: 'lighter'}}>{tx.source.tag}</a> : GLOBAL.PUBLIC_KEY_ID_HEADER + tx.source.publicKeyId.substring(0, 24 - GLOBAL.PUBLIC_KEY_ID_HEADER.length)}</td>
                                    <td>{tx.destinations.length > 1 ? ("MTX (" + tx.destinations.length + ")") :
                                        (tx.destinations[0].address.tag ? <a href={"/tag/" + tx.destinations[0].address.tag} className="navigation-link" style={{fontWeight: 'lighter'}}>{tx.destinations[0].address.tag}</a> : GLOBAL.PUBLIC_KEY_ID_HEADER + tx.destinations[0].address.publicKeyId.substring(0, 24 - GLOBAL.PUBLIC_KEY_ID_HEADER.length) )}
                                    </td>
                                    <td style={{textAlign: 'right'}}>{((tx.totalSendAmount + tx.feeAmount)/1000000000).toFixed(9) + " MCM"}</td>
                                </tr>
                                ))
                            }
                        </tbody>
                    </table>
                    <div style={{width: '100%', height: '70px', display: 'none', margin: '30px 0px 0px 0px'}}>
                        <div style={{display: 'flex', flexDirection: 'column'}}>
                            <div style={{display: 'flex', justifyContent: 'center'}} >
                                <div className="mempool-icon card-table-navigation-btn" onClick={previousTxRange}>
                                    <MdNavigateBefore size={30} style={{opacity: 1}}/>
                                </div>
                                <div style={{margin: '5px 20px 0px 20px'}}>{txRange.start + (mempool.transactions.length <= 0 ? 0 : 1)} to {Math.min(mempool.transactions.length, txRange.end)}</div>
                                <div className="mempool-icon card-table-navigation-btn" onClick={nextTxRange}>
                                    <MdNavigateNext size={30} style={{opacity: 1}}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    );
}

export default Mempool;
